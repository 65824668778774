import { Center, Portal } from '@chakra-ui/react'
import { version } from '@hb/shared'
import {
  AppHeader,
  Container,
  CookieBanner,
  EditableStateProvider,
  LoadingOverlay,
  Menu,
  PopUpMessageOverlay,
  RootModals,
  ScreenContext,
  siteVersions,
  useAuth,
} from '@hb/shared-frontend'
import { MyThreadsListProvider } from '@hb/shared-frontend/components/Messaging/contexts'
import { MessagesWidget } from '@hb/shared-frontend/components/Messaging/MessagesWidget'
import { useFilteredViews, useViewRoutes } from '@hb/shared-frontend/hooks/useFilteredViews'
import { useScreen } from '@hb/shared-frontend/hooks/useScreen'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { views } from './views'

const App = () => {
  const filteredViews = useFilteredViews(views)
  const routes = useViewRoutes(filteredViews)

  const screen = useScreen()
  const { height } = screen

  const loading = useAuth(s => s.loading)
  return (
    <ScreenContext.Provider value={screen}>
      <EditableStateProvider>
        <Portal>
          <MyThreadsListProvider>
            <Container
              style={{
                justifyContent: 'flex-start',
                opacity: loading ? 0 : 1,
                transition: `opacity 333ms ease ${loading ? 0 : 333}ms`,
                boxSizing: 'border-box',
                height: '100vh',
                background: siteVersions[version].providersBackground,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>
              <AppHeader views={filteredViews} />
              <Center position="relative" w="100%" height={`${height - 55}px`}>
                <Routes>
                  {routes.map(({ path, component }) => (
                    <Route key={path} path={path} Component={component} />
                  ))}
                </Routes>
              </Center>
              <MessagesWidget />
              <Menu views={filteredViews} />
            </Container>
            <RootModals />
            <LoadingOverlay
              style={{ fontSize: '21px', fontFamily: 'Comfortaa' }}
              vertical
              height={100}
              loading={loading}
              text="Hamilton Billing"
            />
            <CookieBanner />
          </MyThreadsListProvider>
        </Portal>
        <PopUpMessageOverlay />
      </EditableStateProvider>
    </ScreenContext.Provider>
  )
}

export default App
